
import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';

import { Kpi } from '../components/dashboard/kpi';
import { Barra } from '../components/dashboard/barra';
import { Dona } from '../components/dashboard/dona';
import { getMethod } from 'src/utils/api';

export function DashBoard({idSociety, loggedUser}) {

  const [CAC, setCAC] = useState([]);  
  const [dolar, setDolar] = useState([]);
  const [obra, setObra] = useState([]);  
  const [ADM, setADM] = useState([]);  
  const [pagar, setPagar] = useState([]);  
  const [dataVentas, setVender] = useState([]);  
  const [NP, setNP] = useState([]);  
  const [pedido, setPedido] = useState([]);  

  const modo = idSociety?.modo;
  const moneda = idSociety?.moneda;
  
  useEffect((idSociety) => {
    let id = idSociety?.id > 0? idSociety.id:JSON.parse(localStorage.getItem("idSociety"))?.id;
    
    // dolarhoy
    getMethod(`CAC/mostrarUltDef/${id}`).then((items) => setCAC(items));
    // cac
    getMethod(`dolar/mostrarUltimo/${id}`).then((items) => setDolar(items));
    // para obra
    getMethod(`OP/qntPorEstado/${id}/0/1/0/1`).then((items) => setObra(items));
    // para adm
    getMethod(`OP/qntPorEstado/${id}/0/0/1/0`).then((items) => setADM(items));
    // para pagar
    getMethod(`OP/qntPorEstado/${id}/1/2/0/0`).then((items) => setPagar(items));
    // vendido vs por vender
    getMethod(`fideicomiso/listarVentas/${id}`).then((items) => setVender(items));

    if(modo==="CONS"){
      getMethod(`OC/listarAUTH/${id}/0/0/${moneda}/1`).then((items) => setNP(items.filter(item => item.estadoOC === 1)));
      getMethod(`pedido/listar/${id}`).then((items) => setPedido(items));
    }

  }, [modo, moneda]);

console.log(1111, NP);

  var propPedidoIni = {nombre: 'Pe iniciado', valor: pedido?.filter(item => item.estadoPedido === 1).length, color: 'error.main', avatar: 'FactCheck'};
  var propPedidoCot = {nombre: 'Pe cotizando', valor: pedido?.filter(item => item.estadoPedido === 2).length, color: 'complementary.main', avatar: 'FactCheck'};
  var propNP = {nombre: 'Autorizar NP', valor: NP?.length, color: '#FB8C00', avatar: 'LocalShipping'};

  var propDolar = {nombre: 'Dolar', valor: dolar[0]?.mep, color: 'primary.main', avatar: 'AttachMoney'};
  var propCAC = {nombre: 'CAC', valor: CAC[0]?.definitivo, color: 'error.main', avatar: 'TrendingUp'};

  const data = {
    datasets: [
      {
        data: [ADM, obra, pagar],
        backgroundColor: ['#3F51B5', '#e53935', '#FB8C00'],
        borderWidth: 8,
        borderColor: '#FFFFFF',
        hoverBorderColor: '#FFFFFF'
      }
    ],
    labels: ['Para Autor. Adm', 'Para Autor. Obra', 'Para pagar']
  };

  var propOP = {nombre: 'Ordenes de Pagos', valor: 99, color: 'gray', avatar: 'LocalShipping', data: data};

  return (
    <>

    <Helmet>
        <title>Dashboard </title>
    </Helmet>

    <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid item lg={2} sm={4} xl={2} xs={6}  >  
            {(modo!=="CONS"? null: 
              <Kpi props={propPedidoIni} />        )}
              
            </Grid>
            <Grid item lg={2} sm={4} xl={2} xs={6}  >
            {(modo!=="CONS"? null: 
              <Kpi props={propPedidoCot} />       )}  
              
            </Grid>
            <Grid item lg={2} sm={4} xl={2} xs={6}  >  
            {(modo!=="CONS"? null: 
              <Kpi props={propNP} />      )}
              
            </Grid>
            <Grid item lg={2} sm={4} xl={2} xs={6}  >
        
            </Grid>
            <Grid item lg={2} sm={4} xl={2} xs={6}  >
              <Kpi props={propDolar} />
            </Grid>
            <Grid item lg={2} sm={4} xl={2} xs={6}  >
              <Kpi props={propCAC} />
            </Grid>

            <Grid item lg={8} sm={12} xl={8} xs={12}  >
            {(modo==="CONS"? null: 
              <Barra props={dataVentas} />        )}
            </Grid>
      
            <Grid item lg={4} sm={8} xl={4} xs={12}  >
     
            <Dona props={propOP}  />
     
            </Grid>
      
          </Grid>
        </Container>
      </Box>

    </>
  );
}
